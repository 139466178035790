/* eslint-disable @typescript-eslint/no-misused-promises */
import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { UserMenu } from '../user_menu/UserMenu'
import { NavMenu } from '../nav_menu/NavMenu'
import MenuIcon from '@mui/icons-material/Menu'
import { pca } from '../../hooks/useAzureLogin'
import { useAuth } from '../../contexts/authContext'

const pages = [
  { name: 'My Chats', path: '/list-chats' }
]

const NavBar: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const handleLogin = (): void => {
    navigate('/login')
  }

  const handleLogout = async (): Promise<void> => {
    await pca.logoutPopup()
    navigate('/')
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null)
  }

  const navigateToPage = (path: string): void => {
    handleCloseNavMenu()
    navigate(path)
  }

  return (
        <AppBar position="static" sx={{ backgroundColor: '#6666FF' }}>
            <Container maxWidth="xl">
              <Box pl={6} pr={6}>
                <Toolbar disableGutters>
                    <Typography
                        noWrap
                        component="div"
                        sx={{
                          mr: 2,
                          display: { xs: 'none', md: 'flex' },
                          fontFamily: 'Inter',
                          fontWeight: 600,
                          letterSpacing: '.2rem',
                          color: 'inherit',
                          textDecoration: 'none',
                          border: '2px solid white',
                          padding: '0.5rem'
                        }}
                    >
                        TEAMS EXPORTER
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between' }}>
                        <IconButton
                            size="large"
                            aria-label="open navigation menu"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            noWrap
                            component="div"
                            sx={{
                              fontFamily: 'Inter',
                              fontWeight: 600,
                              letterSpacing: '.2rem',
                              color: 'inherit',
                              textDecoration: 'none',
                              border: '2px solid white',
                              padding: '0.5rem',
                              textAlign: 'center'
                            }}
                        >
                            TEAMS EXPORTER
                        </Typography>
                    </Box>

                    <NavMenu
                        isOpen={Boolean(anchorElNav)}
                        anchorEl={anchorElNav}
                        onClose={handleCloseNavMenu}
                        pages={pages}
                        navigateToPage={navigateToPage}
                    />

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map(({ name, path }) => (
                            <Button
                                key={name}
                                onClick={() => { navigateToPage(path) }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {name}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={currentUser.username} src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <UserMenu
                            isOpen={Boolean(anchorElUser)}
                            anchorEl={anchorElUser}
                            onClose={handleCloseUserMenu}
                            onLogout={handleLogout}
                            onLogin={handleLogin}
                            isLoggedIn={Boolean(currentUser && Object.keys(currentUser).length)}
                            username={currentUser?.username ?? ''}
                        />
                    </Box>
                </Toolbar>
              </Box>
            </Container>
        </AppBar>
  )
}

export default NavBar
