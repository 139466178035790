import { useState, useEffect } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { useNavigate } from 'react-router-dom'
import { AAD_CLIENT_ID, MSAL_REDIRECT_URI, TEAMS_EXPORTER_API_BASE_URL } from '../config'
import { useAuth } from '../contexts/authContext'
import { authentication, app } from '@microsoft/teams-js'

const msalConfig = {
  auth: {
    clientId: AAD_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: MSAL_REDIRECT_URI,
    postLogoutRedirectUri: MSAL_REDIRECT_URI
  }
}

export const pca = new PublicClientApplication(msalConfig)

export const useAzureLogin = (): any => {
  const [isInitialized] = useState(false)
  const navigate = useNavigate()
  const { currentUser, authenticatedRequest } = useAuth()

  // On mount, start the login flow with MSAL
  useEffect(() => {
    const initializeApp = async (): Promise<void> => {
      await app.initialize()
      console.log('Teams SDK initialized')
      authentication.authenticate({
        url: window.location.origin + '/tab-auth/simple-start-v2',
        width: 600,
        height: 535
      })
        .then((result) => {
          console.log('Login succeeded: ' + result)
        })
        .catch((reason) => {
          console.log('Login failed: ' + reason)
        })
    }
    initializeApp().catch((error) => {
      console.error('Error during initialization:', error)
    })
  }, [])

  useEffect(() => {
    if (currentUser) {
      console.log('current user has changed via the login flow:', currentUser)
    }
  }, [currentUser])

  const handleLogin = async (): Promise<void> => {
    if (!isInitialized) {
      console.error('MSAL client is not initialized yet.')
      return
    }

    const loginParams = {
      scopes: ['openid', 'profile', 'email', 'Chat.Read', 'Chat.ReadBasic', 'User.Read']
    }

    try {
      await pca.loginPopup(loginParams)
      await authenticatedRequest({
        method: 'get',
        url: `${TEAMS_EXPORTER_API_BASE_URL}/user-me`
      })
      navigate('/list-chats')
    } catch (error) {
      console.error(error)
    }
  }

  return {
    handleLogin,
    isInitialized
  }
}
