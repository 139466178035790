import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'
import ListChats from './components/list_chats/ListChats'
import NavBar from './components/nav_bar/NavBar'
import LoginPrompt from './components/login_prompt/LoginPrompt'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PaymentForm, { stripePromise } from './components/payment_form/PaymentForm'
import { Elements } from '@stripe/react-stripe-js'
import PaymentSuccess from './components/payment_success/PaymentSucess'
import { pca } from './hooks/useAzureLogin'
import { useAuth } from './contexts/authContext'

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif'
  }
})

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NavigationGuard />
        <Container>
          <Routes>
            <Route path="" element={<LoginPrompt />} />
            <Route path="/list-chats" element={<ListChats />} />
            <Route path="/payment" element={<Elements stripe={stripePromise}><PaymentForm /></Elements>} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
          </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  )
}

const NavigationGuard: React.FC = () => {
  const { currentUser } = useAuth()
  const isAuthenticated = pca.getAllAccounts().length > 0
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('')
    }
    if (currentUser && !currentUser.userTier && isAuthenticated) {
      navigate('/list-chats')
    }
  }, [isAuthenticated, navigate, currentUser])

  return isAuthenticated ? <NavBar /> : null
}

export default App
